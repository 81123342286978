/* eslint-disable react/prop-types */
import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Get, Postdata } from '../../../utils/api.service';
import DataTableBase from "../../common/DataTableBase";
import HttpService from "../../../utils/http.service";
// import { ConvertToDMY, ConvertToDMYHIS, CheckPermission } from "../../../utils/Helper";
import { useDispatch } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import ModalPopup from "../../common/Modal";
import '../../../styles/Profile.scss'
// import CSV from "../../common/CSV";
import Link from "../../common/Link";
// import { isPermission } from "../../../redux/selectors/authSelector";

const DigitainProviderList = () => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(true);
  const dispatch = useDispatch();
//   const [open, setOpen] = useState(false);
//   const [search, setSearch] = useState(false);

  const [modalswitch, setmodalswitch] = useState(false);
  const [modalswitchcontent, setmodalswitchcontent] = useState({});
//   const permission = useSelector(isPermission);

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
        name: 'Provider Id',
        selector: row => row.providerId,
    },
    {
        name: 'Provider Name',
        selector: row => row.providerName,
    },
    {
      name: 'Status',
      cell: (row) =>
        <Link permission={'/digitain/game-sort/s'} status={row.isEnabled}>
          <Form.Check
            type="switch"
            checked={row.isEnabled}
            onChange={e => handleStatusChange(row.providerId, e.target.checked)}
          />
        </Link>,
      button: true,
    }
  ];

  const handleStatusChange = (id, status) => {
    setmodalswitchcontent({id, status});
    setmodalswitch(true);
  };


  const handleStatusChangeAction = () => {
    setmodalswitch(false);
    setIsloading(true);
    Postdata(HttpService.Digitain_provider, {
      providerId: modalswitchcontent.id,
      status: modalswitchcontent.status
    }).then((res) => {
      //TODO: Stop refreshing the whole page and update only the gamedata which was updated or look for other option other than refresh.
      setRefresh(!refresh);
      dispatch(message(res.message));
    }).catch((err) => {
      console.error(err.toString());
      dispatch(message(err.message));
    });
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

//   useEffect(() => {
//     if (search !== false) {
//       const timeOutId = setTimeout(() => setRefresh(!refresh), 500);
//       return () => clearTimeout(timeOutId);
//     }
//   }, [search]);

  useEffect(() => {
    setIsloading(true);
    let params = {
      page: currentPage,
      perpage: perPage,
      // search: (search ? search : ''),
    }
    Get(HttpService.Digitain_provider,{params}).then((response) => {
      // console.log(response)
      if (response.status === 200) {
        setData(response.data);
        setTotalRows(response.count);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, refresh]);

  return (
    <>

      <ModalPopup
        className="alert-box-popup"
        show={modalswitch}
        size="sm"
        onHide={() => setmodalswitch(false)}
        heading="Are you sure?"
      >
        <i className="mdi mdi-check"></i>
        <p>You want to change this status!</p>
        <div className='actions'>
          <button className="btn btn-primary" onClick={() => handleStatusChangeAction()}>Yes, Change Status</button>
          <button className="btn btn-danger" onClick={() => setmodalswitch(false)}>Cancel</button>
        </div>
      </ModalPopup>

      <div className="card-body filters">
        <div className="row searchaction">
          <div className="col-lg-6">
            <h5>
              Provider Management
            </h5>
          </div>
        </div>

        {/* <Collapse in={open}>
          <div>
            <div className="card">
              <p className="card-header">Search</p>
              <div className="card-body">
                <Form.Group className="form-group mb-0">
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={e => setSearch(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </Collapse> */}

        <DataTableBase
          columns={columns}
          loader={isloading}
          data={data}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </div>
    </>
  )
}

export { DigitainProviderList }